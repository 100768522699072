// 卡号为空
export const ERROR_CODE_CARD_NUMBER_EMPTY = '15011';

// 卡号不正确
export const ERROR_CODE_CARD_NUMBER_ERROR = '15012';

// 到期日期为空
export const ERROR_CODE_DATE_EMPTY = '15031';

// // 到期日期 月份比现在小,表示月份过期
// export const ERROR_CODE_DATE_MONTH_EXPIRED = '15033';
//
// // 到期日期 年份份比现在小,表示年份份过期
// export const ERROR_CODE_DATE_YEAR_EXPIRED = '15034';

// 到期日期不正确
export const ERROR_CODE_YEAR_DATE_ERROR = '15032';

// 安全码为空
export const ERROR_CODE_SECURITY_CODE_EMPTY = '15051';

// 安全码不正确
export const ERROR_CODE_SECURITY_CODE_ERROR = '15052';
